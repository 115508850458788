import money1 from './../../images/money1.png'

function GovernmentServices()
{
    return (
        <div className="width-80">
            <h2>Government Services</h2>

            <p className="align-text-left"> Heartland Information Research's first-hand experience serving private sector businesses
                provides government clients valuable insight into current economic conditions and industry best practices. 
                The challenges we hear from public sector clients are significant:
            </p>
            <img src={money1} className='content-image center-image' alt='money'/>
            <div className="center">
            <ul>
                <li className="align-text-left">Ongoing Budget Cuts</li>
                <li className="align-text-left">Growing Number of Programs and Services</li>
                <li className="align-text-left">Hiring Freezes</li>
                <li className="align-text-left">Voids Created by Retiring Managers and Senior Staff</li>
                <li className="align-text-left">Confusion Created by New Software and Processes</li>
                <li className="align-text-left">New Regulatory and Legislative Requirements</li>
                <li className="align-text-left">Missed Deadlines and Quality Issues</li>
            </ul>
            </div>
            <p className="align-text-left"> If you are facing similar challenges or see new opportunities being created by grant programs and economic recovery initiatives, 
                our solutions offer cost-effective alternatives to achieving agency goals and producing positive measureable outcomes.
            </p>
        </div>
    );    
}

export default GovernmentServices;