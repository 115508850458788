import training from './../../images/training.png'

function CrossTraining()
{
    return (
        <div className="width-80">
            <h2>Cross Training</h2>
            <img className='right-image content-image' src={training} alt='Woman being trained by person.'/>
            <p className="align-text-left">
            Of all the reasons behind justifying training investments in today's work environment, 
            by far the most urgent reason is the need to accurately capture and transfer the invaluable knowledge of senior staff before they retire.
            </p>
            <p className="align-text-left">
            Once that knowledge leaves, it may be impossible to retrieve or take many years to rebuild. Can you afford that lag time?
            </p>
            <p className="align-text-left">
            Our staff can help facilitate your cross-training process to ensure knowledge transfer is accurate, 
            complete, and time-effective. By investing in the tools and training needed to plan, schedule, implement, and monitor an effective cross-training program,  
            you and your team will reap years of benefits and avoid losing what has taken decades to learn.
            </p>
        </div>
    );    
}

export default CrossTraining;