import {useState} from 'react';
import { NumericFormat } from 'react-number-format';


function LoanCalculator() {

  const [InterestRate, setInterest] = useState(1.25)
  const [Loan, setLoan] = useState(25000)
  const [Term, setTerm] = useState(12)

  const setRate = event =>
    {
      let value = event.target.value
      value = parseFloat(value).toFixed(2)
      if (isNaN(value) || value <= 0) value = 0
      else if (value >= 30) value = 30
      setInterest(value)
    }

  const setTotal = event =>
    {
      let value = event.target.value
      if (isNaN(value) || value <= 1000) value = 1000
      else if (value >= 10000000) value = 10000000
      setLoan(value)
    }

  const setMonths = event =>
    {
      let value = event.target.value
      if (isNaN(value) || value <= 1) value = 1
      else if (value >= 240) value = 240
      setTerm(value)
    }

  const calculateLoan = () =>
    {
        let formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',})

        let r = (InterestRate / 100) / 12;  
        let a = Loan;  
        let n = Term;  
        
        if (r !== 0) document.getElementById("calculation").textContent = `Monthly payment:  ${formatter.format((a / ((Math.pow(1 + r, n) - 1) / (r * Math.pow(1 + r, n)))).toFixed(2))}`;
        else document.getElementById("calculation").textContent = `Monthly payment: ${formatter.format((a / n).toFixed(2))}`;
    }


    return (
          <div className="width-80">
              <h2>Loan Calculator</h2>
              <label htmlFor="Interest" id="InterestLabel">Interest Rate: {InterestRate}%</label><br/>
              <input type="range" className="slider" id="Interest" name="Interest" min="0.00" max="30.00" value={InterestRate} step="0.01" onInput={setRate}/><br/><br/>
              <input type="number" id="SetInterest" min="0.00" max="30.00" step="0.01" onChange={setRate} onFocus={() => {document.getElementById("SetInterest").value = InterestRate}} placeholder={InterestRate}/>
              <br/><br/>

              <label htmlFor="Loan" id="LoanLabel">Loan Amount: <NumericFormat value={Loan} displayType={'text'} thousandSeparator={true} prefix={'$'}/> </label><br/>
              <input type="range" className="slider" id="Loan" name="Loan" min="1000" max="1000000" value={Loan} onInput={setTotal}/><br/><br/>
              <input type="number" id="SetLoan" min="1000" max="1000000" onChange={setTotal} onFocus={() => {document.getElementById("SetLoan").value = Loan}} placeholder={Loan}/>
              <br/><br/>

              <label htmlFor="Term" id="TermLabel">Term Length: {Term} months</label><br/>
              <input type="range" id="Term" className="slider" name="Term" min="1" max="240" value={Term} onInput={setMonths}/><br/><br/>
              <input type="number" id="SetTerm"  min="1" max="240" onChange={setMonths} onFocus={() => {document.getElementById("SetTerm").value = Term}} placeholder={Term}/>
              <br/><br/>
              <button className="calculate" onClick={calculateLoan}>Calculate Loan</button><br/><br/>

              <p id="calculation">Monthly payment:</p>

              <span>Disclaimer: This calculator is for illustration purposes and actual payments to a lender may vary due to lender’s method of calculating interest, service fees and escrow if applicable.</span>
            </div>
    );
  }

  export default LoanCalculator;