import { Link } from 'react-router-dom';
import button1 from './../images/Button1.png';
import button2 from './../images/Button2.png';
import button4 from './../images/Button4.png';
import button5 from './../images/Button5.png';
import { useMediaQuery } from 'react-responsive'

const Home = () => {
    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  
    return (
    <div> 
        {isDesktopOrLaptop && 
        <div>
            <h1 className='header-text-desktop'>Services We Offer</h1>
                <div className="home-page-images">
                    <Link to={"/SmallBusinessServices"}><img className="home-page-button" src={button2} height="318" width="214" alt="Business Services"/></Link>
                    <Link to={"/GovernmentServices"}><img className="home-page-button" src={button1} height="318" width="214" alt="Goverment Services"/></Link>
                    <Link to={"/TrainingServices"}><img className="home-page-button" src={button4} height="318" width="214" alt="Training Services"/></Link>
                    <Link to={"/FacilitationServices"}><img className="home-page-button" src={button5} height="318" width="214" alt="Facilitation Services"/></Link>
                </div>
                <div className="contact-center">
                <Link to={"/ContactUs"}><button className="contact">Contact Us Today</button></Link>
            </div>
        </div>            
        }
        {isTabletOrMobile && 
            <div>
                <h1 className='header-text-mobile'>Services We Offer</h1>
                <div className="home-page-images">
                    <Link to={"/SmallBusinessServices"}><img className="home-page-button" src={button2} height="212" width="143" alt="Business Services"/></Link>
                    <Link to={"/GovernmentServices"}><img className="home-page-button" src={button1} height="212" width="143" alt="Goverment Services"/></Link>
                    <Link to={"/TrainingServices"}><img className="home-page-button" src={button4} height="212" width="143" alt="Training Services"/></Link>
                    <Link to={"/FacilitationServices"}><img className="home-page-button" src={button5} height="212" width="143" alt="Facilitation Services"/></Link>
                </div>
                <div className="contact-center">
                <Link to={"/ContactUs"}><button className="contact">Contact Us Today</button></Link>
            </div>
        </div>     
        }
    </div>
    );
  }

export default Home;