import dreamstime_globe from './../../images/dreamstime_globe.png'

function FacilitationServices()
{
    return (
        <div className="width-80">
            <h2>Facilitation Services</h2>
            
            <div>
            <img src={dreamstime_globe} className='content-image' alt='globe'></img>
                <div>
                    <p className="align-text-left">Third-party professional facilitation can help break the ice that prevent your organization's forward progress. 
                        These barriers can impact organizational performance by delaying both large and small projects.
                    </p>
                    
                    <p className="align-text-left">Using an outside facilitator brings a new set of eyes to productively bring together competing or conflicting interests in a way that your internal staff would not only find difficult,
                    but potentially perilous to their own careers and office dynamics.
                    </p>
                    
                    <p className="align-text-left">HIR's professional facilitators bring unbiased methodologies for maintaining project schedules, keeping teams on-task, and building positive consensus.
                    </p>

                    <p className="align-text-left">We are effective at bringing together diverse ranges of stakeholders including elected officials, managers, staff, suppliers, and the public.
                    </p>

                    <p className="align-text-left">If it feels like your organization or project is frozen and unable to move forward, let us help!
                    </p>
                </div>
            </div>
        </div>
    );    
}

export default FacilitationServices;