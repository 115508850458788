import seats from './../../images/seats.png'

function EventPlanning()
{
    return (
        <div className="width-80">
            <h2>Event Planning</h2>
            <img className='right-image content-image' src={seats} alt='Rows of empty seats.'/>
            <p className="align-text-left">
            People today place a premium value on their time away from the office, especially in recent years where cutbacks in staffing 
            often means inboxes fill quickly while they are away. Our approach to facilitating conferences and seminars respects the time 
            invested by participants while focusing on achieving a clearly defined return on investment for our clients.
            </p>
            <p className="align-text-left">
            For larger conferences, this process begins by providing an affordable dedicated conference website where participants and presenters can begin dialoging about topics in advance. 
            That means less time at the conference is used for getting organized and making introductions. 
            Using online discussion boards, participants can help shape the conference format and topics to fit their expectations resulting in increased attendance.
            </p>
            <p className="align-text-left">
            This collaborative website can be kept indefinitely for post-conference Q&A and even permanently to house future conference information 
            and materials while archiving past events for future reference. A password protected section provides you and your planning team 24/7 access to 
            conference schedules, speaker presentation files, marketing materials, and online collaboration tools.
            </p>
            <p className="align-text-left">
            Let us show you how to save time and money while delivering conference events that garner positive feedback and return attendance in the future!
            </p>
        </div>
    );    
}

export default EventPlanning;