import logo from './../images/HIRLogoDefined.png';
import { Link } from 'react-router-dom';

const Header = () => {
    return (
      <header>
        <nav>
          <div className='navbar-brand'>
            <div className="banner">Serving Clients for Over 25 Years</div> 
            <Link to={"/"}><img src={logo} alt="HIR Logo" /></Link>
            <div className="info">
              <a href="tel:4147272095">P: 414-727-2095</a> 
              <br/> 
              F: 414-433-1973 
              <br/> 
              <a href="mailto:Info@hirservices.com">Info@hirservices.com</a> 
            </div>

            <div className="headtext">
              Experienced 
              <br/> 
              Responsive 
              <br/> 
              Effective
            </div>
          </div>

        <div className='blue-banner'>
            <div className='dropdown'>
            <button className='dropbtn'><Link className="removeLinkDefault" to={"/"}>Home</Link></button>
            </div>
            
            <div className='dropdown'>
            <button className='dropbtn'><Link className='removeLinkDefault' to={"/WhyUs"}>Why Us</Link></button> 
            </div>

              <div className='dropdown'>
              <button className='dropbtn'><Link className='removeLinkDefault' to={"/GovernmentServices"}>Goverment Services</Link></button>
                <div className='dropdown-content'>
                  <li><Link className="removeLinkDefault" to={"/EventPlanning"}>Event Planning</Link></li>
                  <li><Link className="removeLinkDefault" to={"/PublicOutreach"}>Public Outreach</Link></li>
                  <li><Link className="removeLinkDefault" to={"/FacilitationServices"}>Facilitation</Link></li>
                  <li><Link className="removeLinkDefault" to={"/TrainingServices"}>Training</Link></li>
                  <li><Link className="removeLinkDefault" to={"/Reporting"}>Reporting</Link></li>
                </div>
              </div>

              <div className='dropdown'>
              <button className='dropbtn'><Link className='removeLinkDefault' to={"/SmallBusinessServices"}>Business Services</Link></button>
              <div className='dropdown-content'>
                  <li><Link className="removeLinkDefault" to={"/LEANOffice"}>LEAN Office</Link></li>
                  <li><Link className="removeLinkDefault" to={"/Funding"}>Funding</Link></li>
                  <li><Link className="removeLinkDefault" to={"/Startups"}>Startups</Link></li>
                  <li><Link className="removeLinkDefault" to={"/Expansion"}>Expansion</Link></li>
                  <li><Link className="removeLinkDefault" to={"/Coaching"}>Coaching</Link></li>
                </div>
              </div>

              <div className='dropdown'>
              <button className='dropbtn'><Link className='removeLinkDefault' to={"/FacilitationServices"}>Facilitation Services</Link></button>
              <div className='dropdown-content'>
              <li><Link className="removeLinkDefault" to={"/EventPlanning"}>Confrences</Link></li>
                  <li><Link className="removeLinkDefault" to={"/StakeholderMeetings"}>Public Stakeholder Meetings</Link></li>
                  <li><Link className="removeLinkDefault" to={"/PublicOutreach"}>Public Outreach</Link></li>
                </div>
              </div>

              <div className='dropdown'>
              <button className='dropbtn'><Link className='removeLinkDefault' to={"/TrainingServices"}>Training Services</Link></button>
              <div className='dropdown-content'>
                  <li><Link className="removeLinkDefault" to={"/CrossTraining"}>Cross-Training</Link></li>
                  <li><Link className="removeLinkDefault" to={"/NewHire"}>New Hire</Link></li>
                  <li><Link className="removeLinkDefault" to={"/Compliance"}>Compliance</Link></li>
                </div>
              </div>

              <div className='dropdown'>
              <button className='dropbtn'><Link className='removeLinkDefault' to={"/ContactUs"}>Contact Us Today</Link></button>
              </div>
          </div>    
        </nav>
        </header>
    );
  }
  
  export default Header;