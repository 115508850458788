import LEANOfficeWords from './../../images/LEANOfficeWords.png'

function LEANOffice()
{
    return (
        <div className="width-80">
            <h2>LEAN Office</h2>
            <p className="align-text-left">
            You have survived the downturn. And you know that making more cuts is not the answer. 
            In fact, as the economy sends out recovery signals, you start to wonder if you have the capacity to meet rising demand. 
            But adding people and gearing back up to pre-recession levels is scary. What if you’re wrong?
            </p>
            <img className='center-image LEANOfficeWords' src={LEANOfficeWords} alt='What if you could do "MORE" with the "SAME"? '/>
            <p className="align-text-left">
            After the past few years of cost cutting and staff reductions, you know that making 
            more cut is probably not the answer. You have made all the reductions you can make and still operate.
            </p>
            <p className="align-text-left">    
            LEAN Office uses the same time-proven waste-cutting principles applied for decades on 
            production processes to office environments. LEAN is no longer about making more cuts, it is finding ways to do more with what you still have. 
            LEAN Office is not about finding more ways to cut, it finds ways to do more with what you already have. 
            LEAN Office uses the same time-proven waste-cutting principles applied for decades on production processes to the rest of your organization.
            </p>
            <p className="align-text-left">  
            So as demand for your services increases during the recovery, 
            adopting LEAN Office practices will enable your existing staff to meet those demands without increasing headcounts and overhead expenses.
            Your investment in LEAN Office practices can let you grow without adding expensive headcount and overhead.
            </p>
            <p className="align-text-left">
            If and when public services increases while revenues decline, adopting LEAN Office practices 
            will enable your existing staff to meet those demands without increasing headcounts and overhead expenses.
            Invest in LEAN office improvement now, and let your competitors find out the hard way that the 'old way' no longer works.
            </p>
        </div>
    );    
}

export default LEANOffice;