function Compliance()
{
    return (
        <div className="width-80">
            <h2>Compliance</h2>
            <p className="align-text-left">
            Training performed to comply with government regulations or professional certifications 
            is best done by those specializing in that area. Rules and requirements often change or are reinterpreted by legal cases making 
            it nearly impossible to keep up and stay compliant.dreamstime_8369614.png
            </p>
            <p className="align-text-left">
            We can, however, help you with identifying potential deficiencies 
            in your current training program to avoid being penalized down the road. 
            </p>
            <p className="align-text-left">
            One thing is certain, if you have not reviewed your compliance with the myriad of safety, labor rights, healthcare privacy, 
            and financial control mandates implemented in recent years, it would be a wise investment before a major problem crops up and derails your organization.
            </p>
        </div>
    );    
}

export default Compliance;