import './App.css';
import Footer from './Components/Footer';
import Header from './Components/Header';
import { Routes, Route } from 'react-router-dom';
import LoanCalculator from './pages/TrainingServices/LoanCalculator';
import TrainingServices from './pages/TrainingServices/TrainingServices';
import Home from './pages/Home';
import WhyUs from './pages/WhyUs';
import GovernmentServices from './pages/GovernmentServices/GovernmentServices';
import ContactUs from './pages/ContactUs';
import FacilitationServices from './pages/FaciliationServices/FacilitationServices';
import SmallBusinessServices from './pages/BusinessServices/SmallBusinessServices';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Coaching from './pages/BusinessServices/Coaching'
import Funding from './pages/BusinessServices/Funding'
import Expansion from './pages/BusinessServices/Expansion'
import LEANOffice from './pages/BusinessServices/LEANOffice'
import Startups from './pages/BusinessServices/Startups';
import EventPlanning from './pages/GovernmentServices/EventPlanning';
import PublicOutreach from './pages/GovernmentServices/PublicOutreach'
import Reporting from './pages/FaciliationServices/Reporting'
import StakeholderMeetings from './pages/FaciliationServices/StakeholderMeetings'
import CrossTraining from './pages/TrainingServices/CrossTraining'
import NewHire from './pages/TrainingServices/NewHire'
import Compliance from './pages/TrainingServices/Compliance'
import FundingSourceSearch from './pages/FundingSourceSearch';


function App() {
  return (
    <div className='App'>
    <Header/>
      <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/LoanCalculator' element={<LoanCalculator/>}/>
          <Route path='/TrainingServices' element={<TrainingServices/>}/>
          <Route path='/WhyUs' element={<WhyUs/>}/>
          <Route path='/GovernmentServices' element={<GovernmentServices/>}/>
          <Route path='/ContactUs' element={<ContactUs/>}/>
          <Route path='/FacilitationServices' element={<FacilitationServices/>}/>
          <Route path='/SmallBusinessServices' element={<SmallBusinessServices/>}/>
          <Route path='/LEANOffice' element={<LEANOffice/>}/>
          <Route path='/Coaching' element={<Coaching/>}/>
          <Route path='/Expansion' element={<Expansion/>}/>
          <Route path='/Funding' element={<Funding/>}/>
          <Route path='/GovernmentServices' element={<GovernmentServices/>}/>
          <Route path='/PrivacyPolicy' element={<PrivacyPolicy/>}/>
          <Route path='/Startups' element={<Startups/>}/>
          <Route path='/EventPlanning' element={<EventPlanning/>}/>
          <Route path='/PublicOutreach' element={<PublicOutreach/>}/>
          <Route path='/Reporting' element={<Reporting/>}/>
          <Route path='/StakeholderMeetings' element={<StakeholderMeetings/>}/>
          <Route path='/CrossTraining' element={<CrossTraining/>}/>
          <Route path='/NewHire' element={<NewHire/>}/>
          <Route path='/Compliance' element={<Compliance/>}/>
          <Route path='/FundingSourceSearch' element={<FundingSourceSearch/>}/>
      </Routes>
    <Footer/>
    </div>
  );
}

export default App;
