import meeting from './../../images/meeting.png'

function StakeholderMeetings()
{
    return (
        <div className="width-80">
            <h2>Public Stakeholder Meetings</h2>
            <img className='right-image content-image' src={meeting} alt='People in meeting, talking with papers in front of them.'/>
            <p className="align-text-left">
            Gaining the input or approval of stakeholders can be a challenging and frustrating task, 
            especially with time sensitive decisions or policy making deadlines. Our professional facilitators bring unbiased methodologies to the table for helping stakeholder groups overcome barriers when competing or opposing views delay progress.
            </p>
            <p className="align-text-left">
            Often, simply having an impartial outsider facilitate discussions offers the 
            key to preventing or unlocking  stalemates in stakeholder groups.
            </p>
            <p className="align-text-left">
            We can provide onsite and offsite meeting setup and facilitation, online and telephone conferencing, 
            websites for secure dedicated collaboration and information sharing, and a variety of other tools to engage stakeholders in productive forward moving discussions. 
            </p>
            <p className="align-text-left">
            Whatever your stakeholders need to provide the input entrusted to them, 
            we will help them find a way to deliver.
            </p>
        </div>
    );    
}

export default StakeholderMeetings;