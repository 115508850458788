function ContactUs()
{
    return (
        <div className="width-80">
            <h2>Contact Us</h2>
            <table className="table-border">
            <tbody>
            <tr>
                <th className="table-border">General</th>
                <th className="table-border">Government Inquires</th>
                <th className="table-border">Business Inquires</th>
            </tr>
            <tr>
                <td className="table-border">414-727-2095</td>
                <td className="table-border">414-727-2095</td>
                <td className="table-border">414-727-2095</td>
            </tr>
            </tbody>
            </table>
            <p className="align-text-left">
            Two Locations
            <br/>
            <br/>
            Heartland Information Research, Inc
            6615 W. MIll Road
            Milwaukee, Wisconsin 53218
            <br/>
            <br/>
            Heartland Information Research, Inc
            6434 W North Avenue
            Wauwatosa, WI 53213
            </p>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
    );    
}

export default ContactUs;