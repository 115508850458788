import React, {useState} from 'react';
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";

function FundingSourceSearch() {
//How to Add a New Loan:
//1: Copy template:
//Template:  { id: 0, name: '', maxLoan:0, useOfProceeds:[], maturity:'', mIR: '', gF: ''},
//Note: If it is the last entry in the array, remove the comma at the end of the template. 
//2: Set the ID to whatever the next number is after the previous entry
//3: Input the maximum loan Amount in maxLoan 
//4: Input only the valid Usees of Proceeds, leaving out any that the loan isnt for. Refrence the table below for an example of setting up new loans.  
//Note: Make sure to put commas in between each string.
//Use of Proceeds Key: Startup Business|Refiance Debt|Seasonal Line|Working Capital|Purchase Inventory|Purchase Equipment|Purchase Real Estate|New Construction 
//5: Input Maturity in text form, numbers and words included. 
//6: Input Maximum Insurance Rate, abbreviated as mIR, numbers and words included. 
//7: Input Guaranty Fees, abbreviated as gF, numbers and words included. 

    //Setup of the loans which can be searched.
      let loanSetup = [
        {id: 0, name: '7(a) Loan', maxLoan:5000000, useOfProceeds:["Starting Business", "Refiance Debt", "Seasonal Line", "Working Capital", "Purchase Inventory", "Purchase Equipment", "Purchase Real Estate"], maturity:'5-10 years, 25 years for real estate', mIR: '2.25% to 4.75% over Prime', gF: '2% to 3% of Loan Amount'},
        {id: 1, name: '7(a) Small Loans under $350,000', maxLoan:350000, useOfProceeds:["Starting Business", "Refiance Debt", "Seasonal Line", "Working Capital", "Purchase Inventory", "Purchase Equipment", "Purchase Real Estate"], maturity:'5-10 years, 25 years for real estate', mIR: '2.25% to 4.75% over Prime', gF: '2% to 3% of Loan Amount'},
        {id: 2, name: 'Starting BusinessAExpress', maxLoan:350000, useOfProceeds:["Starting Business", "Seasonal Line", "Working Capital", "Purchase Inventory"], maturity:'Revolving Loan 60 months', mIR: '4.5% to 6.5% over Prime', gF: '2% to 3% of Loan Amount'},
        {id: 3, name: 'CapLines', maxLoan:5000000, useOfProceeds:["Starting Business", "Refiance Debt", "Seasonal Line", "Working Capital", "Purchase Inventory"], maturity:'Up to 10 years', mIR: '2.25% to 4.75% over Prime', gF: '2% to 3% of Loan Amount'},
        {id: 4, name: 'Community Advantage', maxLoan:250000, useOfProceeds:["Starting Business", "Refiance Debt", "Seasonal Line", "Working Capital", "Purchase Inventory", "Purchase Equipment", "Purchase Real Estate"], maturity:'5-10 years, 25 years for real estate', mIR: '6% over Prime', gF: '2% to 3% of Loan Amount'},
        {id: 5, name: 'International Trade', maxLoan:5000000, useOfProceeds:["Working Capital", "Purchase Inventory", "Purchase Equipment", "Purchase Real Estate", "New Construction"], maturity:'5-10 years, 25 years for real estate', mIR: '2.25% to 4.75% over Prime', gF: '2% to 3% of Loan Amount'},
        {id: 6, name: 'Export Working Capital Program', maxLoan:5000000, useOfProceeds:["Working Capital", "Purchase Inventory"],  maturity:'1 to 3 years', mIR: 'TBD by Lender', gF: '2% to 3% of Loan Amount'},
        {id: 7, name: 'Export Express', maxLoan:500000, useOfProceeds:["Starting Business", "Seasonal Line", "Working Capital", "Purchase Inventory"], maturity:'Revolving Loan 60 months', mIR: '4.5% to 6.5% over Prime', gF: '2% to 3% of Loan Amount'},
        {id: 8, name: '504 Loans', maxLoan:5500000, useOfProceeds:["Starting Business", "Purchase Equipment", "Purchase Real Estate", "New Construction"], maturity:'10 to 25 years', mIR: 'TBD at time of loan', gF: '0.5% upfront fee, 0.3205% annual fee'},
        {id: 9, name: '504 Refinancing Loans', maxLoan:5000000, useOfProceeds:["Refiance Debt"], maturity:'10 to 25 years', mIR: 'TBD at time of loan', gF: '0.5% upfront fee, 0.322% annual fee'},
        {id: 10, name: 'Microloans - non-7(a)', maxLoan:50000, useOfProceeds:["Starting Business", "Refiance Debt", "Seasonal Line", "Working Capital", "Purchase Inventory", "Purchase Equipment"], maturity:'Up to 6 years', mIR: '7.75% to 8.5% over Prime', gF: 'None'},
      ];

    const [searchOutput, setSearchOutput] = useState([]);
    const [sBValid, setSBValid] = useState(true);
    let currentDate = new Date();
    const [businessStartDate, setBusinessStartDate] = useState(new Date());
    const [loanAmountNeeded, setLoanAmountNeeded] = useState(0);
    let [noResults, setNoResults] = useState(
      <ul className='no-results'>Search for Loan to see a list of available loans.</ul>
    );
    
    const setLoan = event =>
    {
      let value = event.target.value
      if (isNaN(value) || value <= 0) value = 0
      else if (value >= 100000000) value = 100000000
      setLoanAmountNeeded(value)
    }

    //Searching Logic Notes:
    //Loan Amount Needed looks for loans whose Max Loan Value equal or exceed the input
    //Use of Proceeds looks for loans where the proceeds are valid for that loan, IE checking Startup Business shows all loans where the they allow for startups
    //Business Start Date is additional confirmation of the business that a loan is being taken out for is valid to use Startup loans. 

    const calculateDayDifference = () => {
          //Employer Wants: When the entered date by the user is greater than current system clock date minus 365
            let diff = currentDate - businessStartDate;
            console.log(diff)
            let dayDifference = Math.round(diff / (1000 * 60 * 60 * 24));
            console.log(dayDifference)
            if(dayDifference <= 365) {
                setSBValid((sBValid) => sBValid = true);
          } else {
              setSBValid((sBValid) => sBValid = false);
        }   
    }

    const searchLoan = () => {
        //This part checks all of the checked boxes and reads their id's then puts the ids into an array.
        let checked = document.querySelectorAll(':checked');
        let proceedsArray = Array.from(checked).map(c => c.value);    
        
        //This sets up the filters to eventually apply them to the array. The first filter sets up minimum and maximum.
        //The second filter sorts by the use of proceeds, and checks if there is at least one the proceeds in the entry comparing it to the check box inputs. 
        const filterHandlers = new Map([
            [
              'maxLoan', 
              (val, [min, max]) => val >= min && val <= max
            ],
            [
              'useOfProceeds', 
              (current, useOfProceeds) => useOfProceeds.every(c => current.includes(c))
            ]
          ]);
          
          //Creating the filter by using the objects unique keys and ids. 
          const applyFilters = (arr, filters) => {
            const filterKeys = Object.keys(filters);
            
            return arr.filter(o => filterKeys.every((key) => {
              const handler = filterHandlers.get(key);
              
              return !handler || handler(o[key], filters[key]);
            }));
          }
          
          //Applying the filter.
          const filters = {"maxLoan":[loanAmountNeeded, 100000000],"useOfProceeds":proceedsArray};
          let output = applyFilters(loanSetup, filters);
          setSearchOutput(output)

          console.log(output);
          if(output.length === 0){
            console.log("yeah");
            setNoResults(
                <ul className='no-results'>There are no loans that meet those criteria, please try again.</ul>
            )
          } else {
            setNoResults(
              <ul className='no-results'></ul>
          )
          console.log(noResults);
          }
          //To Do:
          //Logic which outputs a 'there are no loans that meet those criteria, please try again.'
          //Refine appearance of the output. 
          //Bug test and stress test for bugs. 
    }

    const displaySearchOutput = searchOutput.map(loan => 
      <ul className='search-output-display' key={loan.id}>
        <li className='search-output-display'>Name: {loan.name}</li>
        <li className='search-output-display'>Max Loan Amount: ${loan.maxLoan}</li>
        <b><p className='search-output-display'  >Use of Proceeds</p></b>
        {loan.useOfProceeds.map((proceed, index) => {
          return <li className='search-output-display' key={index}>{proceed}</li>
          })}
          <br/>
        <li className='search-output-display'>Maturity: {loan.maturity}</li>
        <li className='search-output-display'>Maximum Insurance Rate: {loan.mIR}</li>
        <li className='search-output-display'>Guaranty Fees: {loan.gF}</li>
        <br/>
        <hr/>
      </ul>
    )
  
    return (
        <div className="width-80">
            <h2>SBA Loan Search:</h2>
            <label htmlFor="Loan Amount Needed" id="LoanAmountLabel">
                <b>Loan Amount Needed (In Dollars):*</b> 
                <br/>
                <input type="number" id="SetLoan" min="0" max="100000000" onChange={setLoan} onFocus={() => {document.getElementById("SetLoan").value = loanAmountNeeded}} placeholder={loanAmountNeeded}/>
            </label>
            <br/>
            <label htmlFor='Business Start Date' id="BusinessStartDateLabel">
            <b>Business Start Date:*</b>
            <br/>
            <i>Note: Businesses with a start date within the last 360 days are not valid for startup loans.</i> 
            <br/> 
            <DatePicker
              dateFormat="dd/MM/yyyy"
              value={businessStartDate.toLocaleDateString()}
              onChange={(date) => {
                const d = new Date(date);
                console.log(d);
                setBusinessStartDate(d);
              }}
              onBlur={calculateDayDifference}
              onFocus={calculateDayDifference}
            />
            </label>
            <br/>
            <b>Use of Proceeds: [Check at least one.]</b> 
            <div className='use-of-proceeds'>
                <ul>
                    {sBValid && <li>
                        <label htmlFor="startingBusiness">Startup Business</label>
                        <input type="checkbox" id="sB" value="Starting Business" name="startingBusiness"/>
                    </li>}
                    <li>
                        <label htmlFor="refinanceDebt">Refinance Debt</label>
                        <input type="checkbox" id="rD" value="Refinance Debt" name="refinanceDebt"/>
                    </li>
                    <li>
                        <label htmlFor="seasonalLine">Seasonal Line</label>
                        <input type="checkbox" id="sL" value="Seasonal Line" name="seasonalLine"/>
                    </li>
                    <li>
                        <label htmlFor="workingCapital">Working Capital</label>
                        <input type="checkbox" id="wC" value="Working Capital" name="workingCapital"/>
                    </li>
                    <li>
                        <label htmlFor="purchaseInventory">Purchase Inventory</label>
                        <input type="checkbox" id="pI" value="Purchase Inventory" name="purchaseInventory"/>
                    </li>
                    <li>
                        <label htmlFor="purchaseEquipment">Purchase Equipment</label>
                        <input type="checkbox" id="pE" value="Purchase Equipment" name="purchaseEquipment"/>
                    </li>
                    <li>
                        <label htmlFor="purchaseRealEstate">Purchase Real Estate</label>
                        <input type="checkbox" id="pRE" value="Purchase Real Estate" name="purchaseRealEstate"/>
                    </li>
                    <li>
                        <label htmlFor="newConstruction">New Construction</label>
                        <input type="checkbox" id="nC" value="New Construction" name="newConstruction"/>
                    </li>
                </ul>
            </div>
            <br/>
            <br/>
            <button className="search-button" onClick={searchLoan}>Search for Loan</button><br/><br/>
            <br/>
            <hr/>
            <ul className='no-results'>{noResults}</ul>         
            <ul className='search-output-display'>{displaySearchOutput}</ul>

        </div>
    )

}

export default FundingSourceSearch;