import { Link } from 'react-router-dom';

const Footer = () => {
    return (
      <div>
        <footer className="mt-auto py-3">
          <div>
            <a href="mailto:Info@hirservices.com">Info@hirservices.com</a> &nbsp; <a href="tel:4147272095">P: 
                414-727-2095</a>
                </div>  
                <p>Copyright © 2023 Heartland Information Research, Inc. All Rights Reserved.</p>
  
              <Link to={"/PrivacyPolicy"}>Privacy Policy</Link>
            <p>6615 W. Mill Road Milwaukee, WI 53218</p>
        </footer>
      </div>
    );
  }
  
  export default Footer;