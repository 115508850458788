import handshake from './../images/handshake.png';

function WhyUs()
{
    return (
        <div className="width-80">
            <h2>Why Us</h2>
            <p className="align-text-left">Heartland Information Research (HIR) is a research and management consulting firm based in Wisconsin that serves government agencies, businesses, and nonprofits.
            Our company mantra captures the straightforward vision of our firm's founder:</p>
            <h4 className="slogan">'We Help People or Find the Resources that Will'</h4>

            <img className="center-img content-image" src={handshake} alt="handshake"/>

            <p className="align-text-left">That means we find solutions, identify opportunities, implement&nbsp;effective processes, eliminate waste, and build stronger connections with stakeholders, customers and employees.
            And if something is beyond our scope we can help find the people that will help.
            </p>
            <p className="align-text-left">
            Our&nbsp;team&nbsp;focuses on delivering solutions built upon the knowledge and experience of your employees, advisors, customers, and stakeholders.
            Without re-inventing the wheel - because often the best answers are hidden in plain site waiting for the right question and opportunity to shine.</p>
            <p className="align-text-left">
            If outside answers are&nbsp;still needed, our information research expertise quickly&nbsp;identifies your industry's best practices and resources to keep things moving forward. <br/>
            It is all about finding the right people and resources.
            </p>
        </div>
    );    
}

export default WhyUs;