import together from './../../images/together.png'
import BusinessAdvisor from './../../files/BusinessAdvisor.pdf'

function Coaching()
{
    return (
        <div className="width-80">
            <h2>Coaching Services</h2>
            <p className="align-text-left">
            For some business owners, the traditional concept of Coaching is not a good fit.
            </p>
            <p className="align-text-left">
            It implies that we talk, <b>you go do it</b>, then you come back and tell us how it went.
            </p>
            <p className="align-text-left">
            But if you had time for more assignments you wouldn't be here looking for help.
            </p>
            <img className='right-image content-image' src={together} style={{maxWidth:100}} alt='Two people, one handing the water bottle to the other'/>
            <p className="align-text-left">
            The fact is you probably experience time-poverty every day and paying someone to assign you more tasks to complete is not what you had in mind. 
            Our <a className="colored-link" href={BusinessAdvisor}>Business Advisory Service</a> includes temporary use of experienced administrative and professional staff to get the less important things off your plate. 
            That gives you the support to breathe a little easier right away, so you have the time and mental clarity to focus on the long haul and big picture.
            </p>
            <p className="align-text-left">
            Part of that bigger picture is finding ways to move that lower level work back over into your organization 
            through delegation, automation, or our favorite - elimination!
            </p>
            <p className="align-text-left">
            So if you are looking for a running buddy rather than a coach on the sidelines, give us a call to discuss your goals and what you need to reach the next level of performance.

            </p>
        </div>
    );    
}

export default Coaching;