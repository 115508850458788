function Reporting()
{
    return (
        <div className="width-80">
            <h2>Reporting</h2>
            <p className="align-text-left">
            Computers may have eliminated paper, but demand for reporting and other forms of written documentation 
            seems to have grown exponentially since the dawn of the computer age. We offer on-demand document editing and report writing services for agencies.
            </p>
            <p className="align-text-left">
            More agencies are using this type of outside support to keep up with growing demand for written communications to public, legislative, internal, and regulatory stakeholders.
            </p>
            <p className="align-text-left">
            The problem is two-fold. Reductions in clerical staff combined with the difficulty of finding employees skilled at drafting public-ready documents 
            makes it challenging to produce quality work in a timely fashion. More importantly, the drafting and editing process can distract agencies from their primary tasks and mission.
            </p>
            <p className="align-text-left">
            We understand the issue and have a proven process that works seamlessly with your internal operation.
            </p>
            <p className="align-text-left">
            A dedicated secure website platform is provided for storing your documents to simplify group editing, review and final approvals. The site supports online collaboration, job specific description and quotations, progress tracking, and project prioritization tools.
            </p>
            <p className="align-text-left">
            Our experience includes economic research reports, technical reports, public flyers and documents, training materials, meeting minutes, brochures, and website content. Our services can be categorized as covering:
            </p>
            ♦  Proof-Reading   ♦  Document Drafting   ♦  Report Writing   ♦  Public Flyers   ♦  Legislative Briefs   ♦ Website Content   ♦ Surveys
        </div>
    );    
}

export default Reporting;