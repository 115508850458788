import new_hire from './../../images/new_hire.png'

function NewHire()
{
    return (
        <div className="width-80">
            <h2>New Hire</h2>
            <img className='right-image content-image' src={new_hire} alt='Woman looking nervous, surrounded by papers and office supplies'/>
            <p className="align-text-left">
            The traditional paper-based hiring and training process is too slow, confusing and 
            expensive for the modern workplace.
            </p>
            <p className="align-text-left">
            In particular, new employees must get up to speed faster than ever but co-workers often have little time to spare 
            helping new people learn the ropes.
            </p>
            <p className="align-text-left">
            The old mantra of 'just read the procedures manual' needs updating to keep your organization competitive in hiring, 
            training and retaining top talent.
            </p>
            <p className="align-text-left">
            On-Boarding is a term describing a better way to plan, implement, and monitor new employee paperwork and training requirements. 
            It requires cross-functional coordination which is why we recommend using LEAN Office events to map out a reliable, cost-effective, 
            and rewarding way to bring new employees onboard!
            </p>

        </div>
    );    
}

export default NewHire;