function TrainingServices()
{
    return (
        <div className="width-80">
            <h2>Training</h2>
            <p className="align-text-left">
            There are six fundamental reasons driving most adult-learning training investments:
            </p>
            <div className="center">

            <ul className="side-by-side">
                <li className="align-text-left">Cross-Training and Knowledge Transfer</li>
                <li className="align-text-left">Compliance Requirements</li>
                <li className="align-text-left">New Hire Training</li>
            </ul>

            <ul className="side-by-side">
                <li className="align-text-left">New Business Systems and Processes</li>
                <li className="align-text-left">Skill Development</li>
                <li className="align-text-left">Customer Support and Education</li>
            </ul>
            </div>
            <p className="align-text-left">
            Our customized training services are designed to deliver cost-effective adult-learner training in both classroom and online settings. 
            Adult-learning techniques are employed to actively engage participants and most importantly draw upon their own knowledge to create a positive, collaborative, and team building environment.
            And our long-term goal is to use train-the-trainer programs to reduce your dependency on outside services by bringing some or all of your training needs back in-house. 
            That includes helping your staff learn how to handle the routine updating of training materials and procedures.
            </p>
        </div>
    );    
}

export default TrainingServices;