import { Link } from 'react-router-dom';

import HoldingSign from './../../images/HoldingSign.png'

function SmallBusinessServices() {
    return (
        <div className="width-80"> 
            <h2>Business Services</h2>
          
            <div>
              <img className="content-image" src={HoldingSign} style={{maxWidth:1000, float:'right'}} alt='HoldingSign'/>
              <p className="align-text-left">Heartland Information Research ( HIR ) provides new business owners a wide range of critical services to help them plan, fund, operate, and grow their business.</p>
              <p className="align-text-left">New business owners face many challenges in starting their business, and HIR acts as their guide and assistant to provide personalized support. Owners get one-on-one consulting service delivered at their level of understanding and within their budget.</p>
              <p className="align-text-left">All projects are quoted as fix-cost contracts allowing owners to accurately plan their startup expenses.</p>
              <p className="align-text-left">Before engaging new startup companies, HIR helps clients leverage free and low cost resources to help them get started. 
              One example are the FREE SBA Online Classes designed to provide new business owners with the information and knowledge needed on key topics. 
              Also, HIR provides this free Checklist to help begin collecting the key information needed to start the new business planning effort. 
              HIR highly recommended getting to know your elected officials to see if they can offer assistance and insights.
              </p>
              <p className="align-text-left">For startups seeking funding, we can develop entire business plans, or preferably walk owners through the steps required using off-the-shelf software such as Business Plan Pro.</p>
              <p className="align-text-left">If a business plan is partially or fully completed, our review will verify key sections before it is taken to potential lenders or investors.</p>

            <div className='button-container'>
            <div className="loan-button-align">
              <Link to={"/LoanCalculator"}><button className="business-button">Loan Calculator</button></Link>
            </div>

            <div className='funding-button-align'>
              <Link to={"/FundingSourceSearch"}><button className="business-button">Find Funding Options</button></Link>
            </div>
            </div>
            
          </div>
        </div>
    );
  }

export default SmallBusinessServices