import money2 from './../../images/money2.png'

function Funding()
{
    return (
        <div className="width-80">

            <h2>Funding</h2>
            <p className="align-text-left">
            There are 6 ways to raise cash for your business:
            <img className='right-image content-image' style={{width: 340}} src={money2} alt='Stack of money on table.'/>
            <ol type="1">
                <li className="align-text-left">Sell more and make more</li>
                <li className="align-text-left">Borrow money</li>
                <li className="align-text-left">Sell ownership shares to investors</li>
                <li className="align-text-left">Collect past-due payments</li>
                <li className="align-text-left">Cut expenses</li>
                <li className="align-text-left">Find elusive grants or tax credits</li>
            </ol>
            All of these cash raising methods will benefit from writing a well thought-out plan, and most will require developing financial projections 
            to justify your actions and the expected return on investment. 
            Our services and resources may help get the cash you need to support your expansion, acquisitions, market repositioning, or startup.
            </p>
            <p className="align-text-left">
            No one can promise you success at raising the funds you need, but we will do our best to help explore them and pursue your options 
            - or work to find other resources available to help you obtain funding.
            </p>
        </div>
    );    
}

export default Funding;