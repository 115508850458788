function PrivacyPolicy() {
    return (
        <div className="width-80">
            <h2>Privacy</h2>
            <h3>Collection of Personal Information</h3>
            <p className="align-text-left">
            Your personal and/or business information may be collected on a voluntary basis through online forms, surveys, or paper forms for specific purposes explained to your prior to the collection of that information.
            We may retain the information you provide and add information from other sources to improve its completeness and accuracy.  
            Our website may collect information relating to your online visit using cookies and other technologies necessary for providing a stable and secure browsing experience.    
            </p> 
            <h3>Uses</h3>
            <p className="align-text-left">
            The primary purpose for collecting your information is to support your online visit to our website and to meet your expectations for service.  
            Information collected from your online visit and transactions may be used to conduct surveys of your client satisfaction level, investigate attemped website security intrusions, and evaluate website performance and usage for future improvements.  
            Information collected during project engagements is intended to help facilitate the deliver of services and products that were requested by you and/or your organization, business, or agency. 
            With your permission, we may use your information for marketing purposes, for automatically distributing information relevant to your interests, and to introduce you to other organizations. 
            </p> 
            <h3>Your Choices</h3>
            <p className="align-text-left">
            You may inform us not to use your information for marketing purposes. For online visits, you can disable cookies in your web browser. 
            Request that we discard your information that may have been collected during your website visit or during a project engagement.  
            </p> 
            <h3>Limitations</h3>
            <p className="align-text-left">
            If retention of your information is required to comply with federal, state, or local agency rules or statutes, then your request to discard your information may need to be qualified. 
            Common examples are grant, contracting, and other funding sources that require us to retain a sufficient amount of evidence that funded projects were completed and in compliance. 
            We will also comply with legally enforceable domestic law enforcement and national security related agency requests for your information. 
            </p>
            <h3>Questions</h3>
            <p className="align-text-left">
            If you have questions regarding our privacy policies, please email them to privacy@hirservices.com.
            </p>
        </div>
    );
}
export default PrivacyPolicy;