import graph from './../../images/graph.png'
import StrategicPlan from './../../files/StrategicPlan.pdf'
import ImplementationPlan from './../../files/ImplementationPlan.pdf'
import MonitoringPlan from './../../files/MonitoringPlan.pdf'
import BusinessPlan from './../../files/BusinessPlan.pdf'

function Expansion()
{
    return (
        <div className="width-80">
            <h2>Expansion</h2>
            <img style={{width: 350}} className='right-image content-image' src={graph} alt='Graph with an arrow going up the graph'/>
            
            <span>
            <a className="colored-link" href={StrategicPlan}>Strategic Planning</a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
            <a className="colored-link" href={ImplementationPlan}>Implementation Planning</a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
            <a className="colored-link" href={MonitoringPlan}>Monitoring Planning</a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
            <a className="colored-link" href={BusinessPlan}>Business Planning</a>
            </span>

            <p className="align-text-left quote">
            “A business can not sustain growth beyond the vision of its owner, nor the expertise of its advisors and employees.”
            </p>
            
            <p className="align-text-left">
            Business expansion efforts often revive the same challenges business owners faced when initially starting their business. 
            </p>
            <p className="align-text-left">
            Accessing capital, developing comprehensive and realistic business plans, assembling a core team of advisors, and balancing time between competing tasks often limit an owner’s expansion efforts.
            </p>
            <p className="align-text-left">
            Expanding businesses use HIR as an additional resource capable of assisting with these and many other challenges. When helping assemble a team of advisors, HIR draws from a wide variety of independent legal, accounting, marketing, IT, and HR specialists.
            </p>
            <p className="align-text-left">
            This team works with business owners during the expansion to build internal business systems that sustain long-term profitable growth.
            </p>
        </div>
    );    
}

export default Expansion;