import microphone from './../../images/microphone.png'

function PublicOutreach()
{
    return (
        <div className="width-80">
            <h2>Public Outreach</h2>
            <img style={{width: 250}} className='content-image' src={microphone} alt='Microphone on stage.'/>
            <p className="align-text-left">
            Public outreach efforts often benefit from a multi-medium approach to engage the widest possible audience in a targeted population.
            </p>
            <p className="align-text-left">
            New methods such as social media tools (Twitter, Facebook, Email blasts) are highly cost-effective and reach both young 
            and older constituents in the community. Traditional methods including direct mail, billboards, flyers, 
            and radio advertisements continue to play an important role in some communities and demographic groups.
            </p>

            <p className="align-text-left">
            Understanding the target audience and how they find and consume information is key to reaching 
            them with the intended message and motivating them to participate.    
            </p>
            <p className="align-text-left">
            We can help plan, develop, and implement outreach activities in partnership with existing 
            community stakeholders while using modern and traditional communication tools to maximize results and minimize costs.
            </p>
        </div>
    );    
}

export default PublicOutreach;